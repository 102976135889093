<script setup lang="ts">
definePageMeta({ layout: "auth" })

const { authState, logIn, toReadableError } = useAuthentication()
const { authErrorMessageTestId } = useTestId()
const formRef = ref<HTMLFormElement>()

const model = useState("login-details", () => ({
  isLoading: false,
  error: undefined as string | undefined,
}))

model.value.isLoading = false
model.value.error = undefined

async function sendLogIn() {
  const { email, password } = authState.value
  try {
    model.value.error = undefined
    model.value.isLoading = true
    await logIn({ email, password })
  }
  catch (error) {
    console.error("ERROR", error)
    model.value.error = toReadableError(error)
    model.value.isLoading = false
  }
}

useHead({
  title: "Login",
})
</script>

<template>
  <div class="h-full w-full flex flex-col items-center justify-center pb-[50px]">
    <div class="w-sm">
      <div class="mb-12 space-y-3">
        <div class="mx-auto w-16">
          <img class="" src="/favicon.png">
        </div>
        <div class="text-center text-3xl t-[#{$global-t-default}]">
          Welcome back
        </div>
        <div class="text-center text-base">
          <nuxt-link :to="{ name: 'signup' }" class="group">
            Looking to <span class="hover-link">sign up</span> with your invite code instead?
          </nuxt-link>
        </div>
      </div>
      <form ref="formRef" @submit.prevent>
        <interface-auth-input
          v-model="authState.email"
          label="E-mail"
          autocomplete="email"
          placeholder="Enter e-mail..."
          autofocus
          @keydown.enter.prevent="sendLogIn()"
        />

        <interface-auth-input
          v-model="authState.password"
          label="Password"
          type="password"
          autocomplete="current-password"
          placeholder="Enter password..."
          @keydown.enter.prevent="sendLogIn()"
        />
        <!-- <div
          class="text-blue-p3 -mt-5 cursor-pointer text-right text-xs font-medium"
        >
          <nuxt-link
            :to="{ name: 'forgot-password' }"
            class="hover:underline"
          >
            Forgot Password?
          </nuxt-link>
        </div> -->
      </form>
      <div class="h-6" />
      <div class="space-y-3">
        <interface-button
          class="h-10 w-full rounded-lg bg-$global-blue-primary text-base font-medium"
          :loading="model.isLoading"
          @click="sendLogIn()"
        >
          Log In
        </interface-button>
        <div class="h-4 w-full text-center text-sm font-medium text-$global-amber-primary">
          <div v-if="model.error" :data-testid="authErrorMessageTestId">
            {{ model.error }}
          </div>
        </div>
      </div>
      <nuxt-link to="/forgot-password">
        <div class="group mx-auto mt-3 w-3/4 text-center text-sm">
          Forgot your password? <span class="hover-link">
            Send a reset code.
          </span>
        </div>
      </nuxt-link>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.hover-link
  @apply font-medium text-$global-blue-primary group-hover:underline
</style>
